// Define Theme Colors

$white: #ffffff;
$lightRed: #ffdada;
$darkLines: #b2b5b6;
$textBlack: #414042;
$lightGreen: #d7f5f0;
$brightGreen: #19b80f;
$lightYellow: #feeac5;
$mediumDarkBlue: #283347;
