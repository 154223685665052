// Define Custom Mixins

@mixin tabletViewport {
  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    @content;
  }
}

@mixin mobileViewport {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
