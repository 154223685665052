// Define Theme Fonts

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// Define Theme Imports

@import './styles/variables';
@import './styles/mixins';

* {
  outline: none !important;
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #636363;
  font-family: 'Roboto', sans-serif !important;
}

#root {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  color: #1d3155;
  font-weight: 700;
  width: fit-content;
}

.container {
  &-wrapper {
    padding-right: 300px;

    @include mobileViewport {
      padding-right: 0px;
    }

    &--nopad {
      padding-right: 0px;
    }

    &-content {
      background-color: $white;
    }
  }
}

.content {
  &-section {
    max-width: 1650px;
    margin-bottom: 72px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &-header {
    padding: 24px 16px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);

    @include mobileViewport {
      justify-content: space-between;
    }

    &-logo {
      max-height: 44px;
      margin-right: 24px;

      @include mobileViewport {
        max-height: 40px;
      }
    }

    &-logosquare {
      max-height: 44px;
      margin-right: 0px;
    }
  }

  &-body {
    padding: 24px 16px;

    &-header {
      margin-bottom: 32px;
    }

    &-title {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 8px;
    }
  }

  &-form {
    margin-bottom: 24px;

    &-header {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  &-footer {
    padding: 24px 16px;
    background-color: $mediumDarkBlue;

    &-copyright {
      font-size: 14px;
      color: #cccccc;
      line-height: 22px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
}

.subscriptions-wrapper {
  .subscription-card {
    margin-bottom: 0px;
  }
}

.subscription-card {
  padding: 16px;
  margin-right: 32px;
  border-radius: 10px;
  margin-bottom: 32px;
  display: inline-block;
  border: 1px solid #1e3256;
  max-width: calc(33.3333% - 32px);

  @include tabletViewport {
    max-width: calc(50% - 32px);
    margin-bottom: 24px !important;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  @include mobileViewport {
    padding: 0px;
    display: flex;
    max-width: initial;
    align-items: center;
    padding-bottom: 16px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px !important;
    margin-right: initial !important;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  &:last-child {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  &-image {
    width: 100%;
    object-fit: cover;
    margin-bottom: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &-title {
    font-size: 24px;
    line-height: 30px;
    color: $textBlack;
    margin-bottom: 32px;
  }

  &-cost {
    font-size: 20px;
    line-height: 26px;
    color: $textBlack;
    margin-bottom: 8px;

    small {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &-quantity {
    width: 100%;

    @include mobileViewport {
      width: initial !important;
    }
  }

  .subscription-button {
    padding: 4px;
    font-size: 18px;
    min-width: 50px;
    color: $textBlack;
    line-height: 22px;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.085);

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $brightGreen;
    }
  }

  .subscription-value {
    font-size: 16px;
    margin: 0px 16px;
    font-weight: 500;
    line-height: 20px;
    color: $textBlack;
    display: inline-block;
  }

  &-overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(4px);
  }

  &-soon {
    font-size: 18px;
    line-height: 22px;
    color: $textBlack;
  }
}

.book-card {
  padding: 16px;
  margin-right: 32px;
  border-radius: 10px;
  margin-bottom: 32px;
  display: inline-block;
  border: 1px solid #1e3256;
  max-width: calc(33.3333% - 32px);

  @include tabletViewport {
    max-width: calc(50% - 32px);
    margin-bottom: 24px !important;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  @include mobileViewport {
    padding: 0px;
    display: flex;
    max-width: initial;
    align-items: center;
    padding-bottom: 16px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px !important;
    margin-right: initial !important;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  &-display {
    width: 100%;
    overflow: hidden;
    max-height: 320px;
    margin-bottom: 8px;
    object-fit: contain;
  }

  &-image {
    height: auto;
    max-width: 100%;
    vertical-align: end;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &-title {
    font-size: 20px;
    line-height: 26px;
    color: $textBlack;
    margin-bottom: 4px;

    @include mobileViewport {
      max-width: 75%;
      margin: 0px auto;
      margin-bottom: 4px;
      text-align: center;
    }
  }

  &-description {
    font-size: 16px;
    line-height: 20px;
    color: $textBlack;
    margin-bottom: 32px;
  }

  &-cost {
    font-size: 20px;
    line-height: 26px;
    color: $textBlack;
    margin-bottom: 8px;

    small {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &-quantity {
    width: 100%;

    @include mobileViewport {
      width: initial !important;
    }
  }

  .book-button {
    padding: 4px;
    font-size: 18px;
    min-width: 50px;
    color: $textBlack;
    line-height: 22px;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.085);

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $brightGreen;
    }
  }

  .book-value {
    font-size: 16px;
    margin: 0px 16px;
    font-weight: 500;
    line-height: 20px;
    color: $textBlack;
    display: inline-block;
  }

  &-overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(4px);
  }

  &-soon {
    font-size: 18px;
    line-height: 22px;
    color: $textBlack;
  }
}

.original-cost {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $darkLines;
  display: inline-block;
  text-decoration: line-through !important;
}

.input-wrapper {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-inliner {
  width: 100%;
}
.input-label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}
.input-field {
  width: 100%;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.085);
}
.input-field:hover,
.input-field:focus,
.input-field:active {
  outline: none;
  box-shadow: none;
  border: 1px solid #19b80f;
}
.input-select {
  height: 42px;
  max-width: 100px;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.085);
}
.input-select-fw {
  height: 42px;
  width: 100%;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.085);
}
.input-select-fw:hover,
.input-select-fw:focus,
.input-select-fw:active {
  outline: none;
  box-shadow: none;
  border: 1px solid #0390f2;
}
.input-field-dp {
  width: 100% !important;
}
.input-message {
  font-size: 12px;
  margin-top: 4px;
  color: #ff0000;
}
.input-wrapper .input-message {
  display: none;
}
.input-checkbox {
  margin-right: 4px;
}
.input-map {
  margin-bottom: 16px;
}

.input-error .input-field {
  border: 1px solid #ff0000;
  background-color: rgba(145, 18, 35, 0.065);
}
.input-error .input-message {
  display: block;
}
.input-label a {
  margin: 0px 4px;
  font-weight: 600;
  color: #0390f2;
}
.input-label a:hover,
.input-label a:focus,
.input-label a:active,
.input-label a:visited {
  text-decoration: none;
}

.input-assist {
  margin: 0px;
  display: block;
  font-size: 400;
  font-size: 14px;
  line-height: 20px;
}

.page-loader {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.85);
}
.page-loader-asset {
  margin-bottom: 8px;
}
.page-loader-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: $brightGreen;
}
